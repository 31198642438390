import React from "react"
import FotoKontigo from "@components/pageCasosdeExito/image/imgPhotoEmpresa/ittsabus/FotoIttsaBus.png"
import FotoKontigo2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/ittsabus/FotoIttsaBus2.png"

//logo de empresas clientes
import IttsaBus from "@components/pageCasosdeExito/image/logoscustomer/IttsaBus.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/ittsabus/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/ittsabus/img2.svg"

const text1 = (
  <span className="">
    IttsaBus es una de las compañías más importantes de transporte terrestres
    del norte del Perú. Desde hace 30 años viene apostando por brindar la mejor
    experiencia a sus usuarios a través de una moderna flota de buses y servicio
    personalizado.
    <br /> <br />
    Su operación de Contact Center ha logrado mejorar sus métricas más
    importantes de satisfacción con la ayuda de Beex. Actualmente han mejorado
    sus tiempos de respuesta en más del 95%, teniendo como canales principales
    el telefónico y WhatsApp.
  </span>
)

const text2 = (
  <span>
    Ittsabus, una de las empresas más representativas de transportes de
    pasajeros en el Perú, ha logrado brindar uno de los mejores servicios con la
    adquisición de una moderna flota de buses, que destacan por su comodidad y
    confort.
    <br /> <br />
    La compañía, desde el inicio, se ha propuesto ser reconocida a nivel
    nacional como una de las mejores empresas de transporte terrestre de
    pasajeros y de carga, demostrándolo no solo con su eficiencia, calidad y
    seguridad en cada servicio, sino también con la tecnología brindada al
    usuario para mejorar su experiencia.
    <br /> <br />
    Iris Pérez Medina, Coordinadora de Marketing y Servicio al Cliente, ha
    acompañado desde el inicio en la implementación de Beex. De hecho, ha sido un
    contacto clave en la evaluación y decisión del sofware que necesitaba la
    empresa.
    <br /> <br />
    IttsaBus buscaba mejorar la experiencia digital que se le brindaba a sus
    usuarios para sus procesos más importantes, como la compra de pasajes,
    atención de consultas y reclamos, y más. Para esto, evaluó diferentes puntos
    de software omnicanales, siendo Beex el que mejor relación tenía en cuánto
    a funcionalidades, prestaciones y presupuesto.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “El implementar las soluciones omnicanales de Beex ha tenido un impacto
    transformador en nuestras operaciones. Hemos logrado centralizar, gestionar
    y automatizar tareas, así como personalizar la comunicación y organizar la
    información de nuestros clientes.”
  </p>
)

const text4 = (
  <span>
    Como menciona Iris, la experiencia con la solución y los equipos ha sido más
    que satisfactoria, logrando mejorar sus procesos más importantes gracias a
    la autonomía en autogestión de la plataforma y, además, con el
    acompañamiento del equipo de Customer Success de Beex.
  </span>
)

const text5 = (
  <span>
    Unos de los objetivos principales para la operación de IttsaBus, era el
    centralizar e integrar sus canales de contacto principales. Antes de
    implementar Beex en la nube, su principal punto de contacto era el canal
    telefónico, por lo que contaban con un software independiente para ello.
    <br /> <br />
    Algo a tener en cuenta, es que también usaban WhatsApp, pero la versión
    Business tradicional. Por lo que algo importante dentro del proceso de
    implementación era acceder al API de WhatsApp Business para así optimizar
    sus procesos de automatización.
    <br /> <br />
    Y esto se logró en tiempo record gracias al trabajo mutuo de los equipos.
    Actualmente, IttsaBus cuenta con 2 diferentes números de WhatsApp API, para
    atención al cliente y para su canal de ventas digitales.
    <br /> <br />
    Además, se agregaron otros canales digitales, como livechat en su web,
    Facebook Messenger e Instagram. Esta vista 360 de sus clientes les ha
    permitido mejorar sus principales métricas de satisfacción. Además, sus
    agentes de Contact Center cuentan con una herramienta capaz de poder atender
    todas sus interacciones de forma eficiente.
  </span>
)
const text6 = (
  <span>
    “Tener integrados nuestros canales de atención más importantes, como el
    telefónico, WhatsApp, livechat y redes sociales en una sola plataforma nos
    brinda múltiples beneficios, incluyendo la centralización de la
    comunicación, una mejor experiencia para el cliente, además de ahorro de
    tiempos y recursos.”
  </span>
)

const text7 = (
  <span>
    Adicional al tradicional e importante canal telefónico, Ittsabus sumó
    WhatsApp a sus operaciones. A través del API oficial de WhatsApp Business,
    la empresa ha dado de alta 2 números para diferentes operaciones digitales.
    <br /> <br />
    Esto, les ha permitido múltiples beneficios, en el que destacan el diseño y
    creación de chatbots. Al ser una empresa que maneja miles de interacciones,
    la automatización de su comunicación era un punto importante a tener en
    cuenta para optimizar, y los chatbots en un canal tan importante ayudaron
    mucho en su gestión.
    <br /> <br />
    Actualmente, para su operación de atención al cliente, sus chatbots ayudan
    de forma autónoma a sus usuarios, brindado información y respondiendo
    consultas o preguntas frecuentes.
    <br /> <br />
    Además, WhatsApp ha mejorado de forma eficiente su proceso de reprogramación
    de viajes, ya que ahora sus usuarios cuentan con un canal directo para
    realizar este proceso. Además, la empresa es capaz de lanzar recordatorios
    automáticos para notificaciones sobre reprogramaciones y otros casos.
    <br /> <br />
    Como menciona Iris, el contar con esta versión de WhatsApp les ha permitido
    mejorar su atención al cliente, agilizar su comunicación, personalizar sus
    mensajes, automatizar tareas y realizar un seguimiento efectivo a sus
    interacciones.
  </span>
)
const text8 = (
  <span>
    “Somos capaces de crear chatbots personalizados en WhatsApp sin requerir de
    conocimientos técnicos complejos. Ahora nuestra atención es inmediata y
    somos capaces de escalar nuestra capacidad de respuesta, personalizar la
    experiencia y estar presentes en un canal cercano a nuestros clientes. Esto
    ha hecho que mejoremos en 22% la satisfacción de nuestros usuarios para su
    reprogramación de viajes.”
  </span>
)

const text9 = (
  <span>
    Una funcionalidad que también ha sumado mucho a la operación digital de
    Ittsabus, es el soporte multiagente y enrutamiento inteligente de tickets
    con el que cuenta Beex para WhatsApp, a través del API oficial. Además de
    los canales tradicionales como livechat y redes sociales, como Messenger e
    Instagram.
    <br /> <br />
    Para sus aproximadamente 30 agentes de Contact Center, Ittsabus cuenta con
    canales oficiales configurados en colas de atención. Con esto, ha logrado
    distribuir correctamente la carga de conversaciones según sus reglas de
    negocio.
    <br /> <br />
    Como nos cuentan, el poder tener primeras respuestas y flujos automatizados
    con chatbots, además de tener siempre disponible a un agente para que
    continue la conversación si así se amerita, es ideal para la satisfacción de
    los usuarios, ya que están presentes siempre que los necesitan.
  </span>
)
const text10 = (
  <span>
    “Con la gestión inteligente de colas en Beex hemos optimizado nuestra
    distribución de carga de trabajo y facilitado la colaboración entre los
    miembros del equipo. Además que mantenemos la continuidad en nuestra
    atención al cliente, mejorado la eficiencia operativa y somos capaces de
    realizar un seguimiento individualizado del desempeño.”
  </span>
)

const text11 = (
  <span>
    Para sumar al caso en general, nuevamente, uno de los puntos que más valoran
    nuestros clientes es el soporte dedicado y acompañamiento que brindamos a
    sus operaciones, y con IttsaBus, es algo que ha sobresalido desde el inicio.
    <br /> <br />
    Al ser una operación de atención al cliente, marketing y ventas de una
    empresa conocida en el medio, las consultas y solicitudes son numerosas
    debido al ambiente cambiante de su negocio. Y Beex Customer Success
    siempre ha estado presente en cada requerimiento que la operación de esta
    importante compañía ha tenido.
    <br /> <br />
    Como nos cuenta Iris, el soporte y acompañamiento que les hemos
    proporcionado durante este tiempo de servicios ha sido excepcional. El
    equipo de Beex Customer Success ha demostrado disponibilidad,
    profesionalismo, conocimiento y compromiso en brindarles la mejor
    experiencia de soporte posible.
    <br /> <br />
    Esto, ha llevado a que el equipo de IttsaBus se sienta satisfecho con
    nuestros servicios y soluciones. Demostrando que contamos con un equipo
    confiable, efectivo y 100% orientado al cliente. Lo cual ha generado
    resultados positivos para su negocio.
  </span>
)

const text12 = (
  <span>
    “El acompañamiento en la implementación de Beex, las actualizaciones
    constantes y la relación de confianza que se ha desarrollado, nos han
    ayudado a optimizar nuestra operación y obtener resultados satisfactorios.
    Estamos super satisfechos con el soporte recibido en nuestra operación
    diaria.”
  </span>
)

const text13 = (
  <p style={{ marginBottom: "70px" }}>
    Estos son algunos de los procesos con los que Beex
    ayuda a IttsaBus en su operación de atención al cliente, marketing y ventas:
  </p>
)

const text14 = (
  <p style={{ marginBottom: "70px" }}>
    Beex e IttsaBus han generado una relación de confianza basada en el
    gran trabajo realizado por sus equipos. Para nosotros es una super
    oportunidad el brindarle servicios a una marca tan importante y reconocida
    de este sector de transporte terrestre.
  </p>
)

const data = {
  intro: {
    image: IttsaBus,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoKontigo,
    text4: text4,
    title5: "Operación omnicanal",
    text5: text5,
    text6: text6,
    title7: "WhatsApp para automatizaciones y notificaciones",
    text7: text7,
    text8: text8,
    fototwo: FotoKontigo2,
    title9: "Enrutamiento inteligente de tickets",
    text9: text9,
    text10: text10,
    title11: "Experiencia de soporte",
    text11: text11,
    text12: text12,
    text13: text13,
    text14: text14,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "Blending",
      img: blending,
      info:
        "Sus agenes recepcionan, pero también realizan llamadas salientes ante cualquier necesidad. Reduciendo los tiempos muertos de su operación y optimizando su gestión diaria con IVRs.",
    },
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Gestión y envío de campañas de IVR, como encuestas de satisfacción con selección DTMF para devolución de contacto a teleoperadores en tiempo real.",
    },
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial certificado de WhatsApp Business API para sus operaciones. Beex logra que sus clientes conversen con IttsaBus mediante la app más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
    {
      title: "Livechat y redes sociales",
      img: livechat,
      info:
        "Chat directo desde la página web de Ittsabus. Integración simple y rápida sin necesidad de tocar código de forma avanzada. Automatización con chatbots personalizados para cada canal o landing.",
    },
  ],
}

export default data
